import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { IonAlert, IonButton, IonSpinner, IonText } from "@ionic/react";
import { OpenNativeSettings } from "@ionic-native/open-native-settings";
import { api } from "@src/app/api";
import { getOrWatchCurrentLocation } from "@src/app/common/location";
import { useRadarLocationApi } from "@src/app/hcpShifts/custom-hooks/useRadarLocationHook";
import {
  calculateShiftMandatoryBreakTimes,
  doesShiftHaveWorkedBreak,
} from "@src/app/hcpShifts/helper";
import { useSession } from "@src/app/store/helperHooks";
import { validateTimeKeeping } from "@src/app/store/ongoingShifts/apiV2";
import { checkLocationAwareness } from "@src/app/utils/locationHelper";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { isCapacitorPlatform, useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import {
  LegacyGeoLocationCoordinates,
  calculateGeoDistanceInMiles,
  convertToGeoLocation,
} from "@src/appV2/Location";
import { USER_EVENTS, UserEvent } from "@src/constants";
import { LegacyGeoLocationCoordinatesAndType } from "@src/lib/interface";
import { Shift, TimeRange } from "@src/lib/interface/src/lib/shift";
import { differenceInMinutes, parseISO } from "date-fns";
import { useState } from "react";
import "./style.scss";

import { ShiftActionTime } from "./shiftActionTime";
import { useAdjustTimeForMultiDayShifts } from "./useAdjustTimeForMultiDayShifts";
import {
  getClockInTimeError,
  getClockOutTimeError,
  getLunchInTimeError,
  getLunchOutTimeError,
  validateShiftTime,
} from "../../../utils/shiftEventTimeValidation";
import { getGeofenceStatus } from "../custom-hooks/getGeofenceStatus";
import { ShiftDetailsAlerts, useDeprecatedAlertsForShiftDetails } from "../shiftDetails/alerts";
import {
  getClockInEditHelperText,
  getClockOutEditHelperText,
  getLunchInEditHelperText,
  getLunchOutEditHelperText,
} from "../utils/shiftTimeEditHelperText";

type ShiftTimeSummaryProps = {
  shift: Shift;
  originalClockInOut: TimeRange;
  originalLunchInOut: TimeRange;
  initialSubmissionClockInOut: TimeRange;
  initialSubmissionLunchInOut: TimeRange;
  onShiftTimeEdit: (isOpen: boolean) => void;
  onShiftTimeSave: (
    submitClockIn?: string,
    submitClockOut?: string,
    submitLunchOut?: string,
    submitLunchIn?: string
  ) => void;
  isEditable: boolean;
  isTimeEditButtonVisible: boolean;
  isNewTimeSheetEnabled: boolean;
  isEarlyClockInEnabled: boolean;
  geolocationTrackingForShiftsEnabled: boolean;
  isSolveUnpaidBreaksEnabled: boolean;
  isMandatoryBreakEnabled: boolean;
};

interface ShowTooFarAwayProps {
  isLocationExperienceEnabled?: boolean;
  location?: [long: number, lat: number];
}

export const ShiftTimeSummary = ({
  shift,
  originalClockInOut,
  originalLunchInOut,
  initialSubmissionClockInOut,
  initialSubmissionLunchInOut,
  onShiftTimeEdit,
  onShiftTimeSave,
  isEditable,
  isTimeEditButtonVisible,
  isNewTimeSheetEnabled,
  isEarlyClockInEnabled,
  geolocationTrackingForShiftsEnabled,
  isSolveUnpaidBreaksEnabled,
  isMandatoryBreakEnabled,
}: ShiftTimeSummaryProps) => {
  const [shiftGeofence, setShiftGeofence] = useState(shift.geofence);

  const isRadarTimekeepingValidationsEnabled =
    shift.facility?.featureSettings?.radarTimekeepingValidations ?? false;

  const { checkFacilityGeofence } = useRadarLocationApi(
    shift.facility?.userId ?? "",
    shift?._id?.toString() ?? ""
  );

  const geofenceData = isRadarTimekeepingValidationsEnabled ? shiftGeofence : undefined;

  let shiftTime = 0;
  let lunchTime = 0;

  if (isDefined(initialSubmissionClockInOut.start) && isDefined(initialSubmissionClockInOut.end)) {
    shiftTime =
      differenceInMinutes(
        parseISO(initialSubmissionClockInOut.end),
        parseISO(initialSubmissionClockInOut.start)
      ) / 60;
  }
  if (isDefined(initialSubmissionLunchInOut.start) && isDefined(initialSubmissionLunchInOut.end)) {
    lunchTime =
      differenceInMinutes(
        parseISO(initialSubmissionLunchInOut.end),
        parseISO(initialSubmissionLunchInOut.start)
      ) / 60;
  }

  const FACILITY_SAFE_DISTANCE_MILES = 0.75;

  const facilityTimezone = shift?.facility?.tmz ? shift.facility.tmz : "";

  const [showSpinner, setShowSpinner] = useState(false);
  const timeElapsed = (shiftTime as number) - (lunchTime || 0);

  const hours = Math.trunc(timeElapsed);
  const minutes = Math.round((timeElapsed - hours) * 60);

  const [submitClockIn, setSubmitClockIn] = useState(initialSubmissionClockInOut.start);
  const [submitClockOut, setSubmitClockOut] = useState(initialSubmissionClockInOut.end);
  const [submitLunchOut, setSubmitLunchOut] = useState(initialSubmissionLunchInOut.start);
  const [submitLunchIn, setSubmitLunchIn] = useState(initialSubmissionLunchInOut.end);

  const [clockInEditText, setClockInEditText] = useState("");
  const [lunchInEditText, setLunchInEditText] = useState("");
  const [lunchOutEditText, setLunchOutEditText] = useState("");
  const [clockOutEditText, setClockOutEditText] = useState("");

  const [isBreakTimeTouched, setIsBreakTimeTouched] = useState(false);

  const { showErrorToast } = useToast();
  const { env } = useSession();

  const shiftDetailsAlerts: ShiftDetailsAlerts = useDeprecatedAlertsForShiftDetails();
  const { alert } = shiftDetailsAlerts;

  const { adjustTimeForMultiDayShifts } = useAdjustTimeForMultiDayShifts(shift);

  const hasGeofencedBreak = Boolean(shiftGeofence?.startBreak || shiftGeofence?.endBreak);

  const breakDurationInMinutes =
    isDefined(submitLunchOut) && isDefined(submitLunchIn)
      ? differenceInMinutes(parseISO(submitLunchIn), parseISO(submitLunchOut))
      : 0;

  const hasWorkedBreakTime =
    isSolveUnpaidBreaksEnabled &&
    isMandatoryBreakEnabled &&
    doesShiftHaveWorkedBreak({
      clockInOut: {
        start: submitClockIn,
        end: submitClockOut,
      },
      lunchInOut: {
        start: submitLunchOut,
        end: submitLunchIn,
      },
    });

  const openLocationSettings = async () => {
    if (isCapacitorPlatform()) {
      await OpenNativeSettings.open("location");
    } else {
      setTimeout(() => {
        shiftDetailsAlerts.alertBrowserLocationAccess();
      }, 500);
    }
  };

  const showTooFarAwayPopup = (props: ShowTooFarAwayProps) => {
    const { isLocationExperienceEnabled, location } = props;
    shiftDetailsAlerts.alertReturnToTheFacility({
      facilityName: shift.facility?.name,
      isEdit: true,
      tryAgainBtnHandler: onSave,
      skipLocationBtnHandler: () => onCancel(),
    });
    logEvent(USER_EVENTS.TOO_FAR_AWAY_PANEL, {
      shiftId: shift._id,
      stage: "TIMESHEET",
      isLocationExperienceEnabled,
      location,
      facilityLocation: shift.facility?.geoLocation?.coordinates,
    });
  };

  const isHyperTrackEnabledForClockInOut = useCbhFlag(CbhFeatureFlag.HYPERTRACK_CLOCK_IN_OUT, {
    defaultValue: false,
  });

  const locationCheck = async () => {
    const {
      isLocationExperienceEnabled,
      isOutsideFacilityGeofence,
      location: updatedLocation,
    } = await getGeofenceStatus({
      shift,
      isHyperTrackEnabledForClockInOut,
      checkFacilityGeofence,
    });

    if (isLocationExperienceEnabled && isOutsideFacilityGeofence) {
      showTooFarAwayPopup({
        location: updatedLocation,
        isLocationExperienceEnabled: true,
      });

      return !isOutsideFacilityGeofence;
    }

    let location: LegacyGeoLocationCoordinates | undefined;
    let positionError;

    // For certain facility types, we do not want to do location-based validations
    // If we do not want to track agent's location for this shift, we default to using the facility's location
    if (geolocationTrackingForShiftsEnabled) {
      const locationResult = await getOrWatchCurrentLocation(10000);
      ({ location } = locationResult);
      positionError = locationResult.error;
    } else {
      location = shift.facility?.geoLocation?.coordinates;
      positionError = null;
    }

    if (positionError) {
      shiftDetailsAlerts.alertLocationAccess({
        openLocationSettingsFn: openLocationSettings,
        skipLocationBtnHandler: () => onCancel(),
        isEdit: true,
      });
      return false;
    }
    //safe distance check
    const { coordinates } = shift.facility?.geoLocation as LegacyGeoLocationCoordinatesAndType;
    const distance = calculateGeoDistanceInMiles(
      convertToGeoLocation(coordinates),
      convertToGeoLocation(location as LegacyGeoLocationCoordinates)
    );
    const isLocationAware = checkLocationAwareness(shift?.facility?.locationAwareness as string);
    const safeDistanceFromFacility = env?.facilitySafeDistanceMiles || FACILITY_SAFE_DISTANCE_MILES;

    if (isLocationAware && distance > safeDistanceFromFacility) {
      showTooFarAwayPopup({ location, isLocationExperienceEnabled: false });
      return false;
    }
    return true;
  };

  const sendEditableErrorToSegment = (
    userEvents: UserEvent,
    errorMessage: string,
    submittedTime?: string
  ) => {
    logEvent(userEvents, {
      error: errorMessage,
      submittedTime,
      shiftId: shift._id,
      shiftStart: shift.start,
      shiftEnd: shift.end,
      isEarlyClockInEnabled,
      actualClockOutTime: shift?.clockInOut?.end,
      geofence: geofenceData,
    });
  };

  const validateNewClockIn = async (time: string) => {
    const errorMessage = getClockInTimeError({
      time,
      shiftStart: shift.start as string,
      shiftEnd: shift.end as string,
      isEarlyClockInEnabled,
      geofence: geofenceData,
      timezone: shift.facility?.tmz,
    });
    if (!errorMessage && shift.clockInOut?.end) {
      const errorTimekeepingMessage = await validateTimeKeeping({
        shiftId: shift._id!.toString(),
        editedClockIn: time,
        editedClockOut: shift.clockInOut.end,
      });
      return errorTimekeepingMessage ?? "";
    }
    return errorMessage;
  };

  const onClockInChange = async (time: string) => {
    const errorMessage = await validateNewClockIn(time);
    if (errorMessage) {
      sendEditableErrorToSegment(USER_EVENTS.EDIT_CLOCK_IN__ERROR, errorMessage, time);
      showErrorToast(errorMessage);
    } else {
      setSubmitClockIn(time);
      if (isDefined(submitClockOut)) {
        applyMandatoryBreak({ clockInOut: { start: time, end: submitClockOut } });
      }
    }
  };

  const onLunchOutChange = (time?: string) => {
    const errorMessage = getLunchOutTimeError({
      time,
      geofence: isSolveUnpaidBreaksEnabled ? undefined : geofenceData,
      timezone: shift.facility?.tmz,
    });
    if (errorMessage) {
      sendEditableErrorToSegment(USER_EVENTS.EDIT_START_BREAK_ERROR, errorMessage, time);
      showErrorToast(errorMessage);
    } else {
      setSubmitLunchOut(time);
      setIsBreakTimeTouched(true);
    }
  };

  const onLunchInChange = (time?: string) => {
    const errorMessage = getLunchInTimeError({
      time,
      lunchOutTime: submitLunchOut,
      geofence: isSolveUnpaidBreaksEnabled ? undefined : geofenceData,
      timezone: shift.facility?.tmz,
    });
    if (errorMessage) {
      sendEditableErrorToSegment(USER_EVENTS.EDIT_END_BREAK_ERROR, errorMessage, time);
      showErrorToast(errorMessage);
    } else {
      setSubmitLunchIn(time);
      setIsBreakTimeTouched(true);
    }
  };

  const validateNewClockOut = async (time: string) => {
    const errorMessage = getClockOutTimeError({
      time,
      shiftStart: shift.start,
      actualClockOutTime: shift?.clockInOut?.end,
      shiftEnd: shift.end,
    });
    if (!errorMessage && shift.clockInOut?.start) {
      const errorTimekeepingMessage = await validateTimeKeeping({
        shiftId: shift._id!.toString(),
        editedClockIn: shift.clockInOut.start,
        editedClockOut: time,
      });
      return errorTimekeepingMessage ?? "";
    }
    return errorMessage;
  };

  const onClockOutChange = async (time: string) => {
    const errorMessage = await validateNewClockOut(time);
    if (errorMessage) {
      sendEditableErrorToSegment(USER_EVENTS.EDIT_CLOCK_OUT_ERROR, errorMessage, time);
      showErrorToast(errorMessage);
    } else {
      setSubmitClockOut(time);
      if (isDefined(submitClockIn)) {
        applyMandatoryBreak({ clockInOut: { start: submitClockIn, end: time } });
      }
    }
  };

  function applyMandatoryBreak(params: { clockInOut: { start: string; end: string } }) {
    const { clockInOut } = params;
    const doesNeedMandatoryBreak =
      isSolveUnpaidBreaksEnabled &&
      isMandatoryBreakEnabled &&
      doesShiftHaveWorkedBreak({
        clockInOut,
        lunchInOut: {
          start: submitLunchOut,
          end: submitLunchIn,
        },
      });

    if (doesNeedMandatoryBreak && !isBreakTimeTouched) {
      const defaultBreakTimes = calculateShiftMandatoryBreakTimes({
        clockInOut,
      });

      if (isDefined(defaultBreakTimes)) {
        setSubmitLunchOut(defaultBreakTimes.start.toISOString());
        setSubmitLunchIn(defaultBreakTimes.end.toISOString());
      }
    }
  }

  const onSave = async () => {
    setShowSpinner(true);
    const updatedSubmitClockIn = adjustTimeForMultiDayShifts(submitClockIn);
    const updatedSubmitClockOut = adjustTimeForMultiDayShifts(submitClockOut);
    const updatedSubmitLunchOut = adjustTimeForMultiDayShifts(submitLunchOut);
    const updatedSubmitLunchIn = adjustTimeForMultiDayShifts(submitLunchIn);
    setSubmitClockIn(updatedSubmitClockIn);
    setSubmitClockOut(updatedSubmitClockOut);
    setSubmitLunchOut(updatedSubmitLunchOut);
    setSubmitLunchIn(updatedSubmitLunchIn);
    const errorMessage = validateShiftTime(
      updatedSubmitClockIn,
      updatedSubmitClockOut,
      updatedSubmitLunchOut,
      updatedSubmitLunchIn
    );
    if (errorMessage) {
      sendEditableErrorToSegment(USER_EVENTS.EDIT_TIMESHEET_SAVE_ERROR, errorMessage);
      showErrorToast(errorMessage);
      setShowSpinner(false);
      return;
    }

    // Location Radar geofence check
    const isLocationCheckPassed = await locationCheck();
    if (isLocationCheckPassed) {
      setClockInEditText(getClockInEditHelperText(updatedSubmitClockIn, originalClockInOut.start));
      setClockOutEditText(getClockOutEditHelperText(updatedSubmitClockOut, originalClockInOut.end));
      setLunchOutEditText(
        getLunchOutEditHelperText(updatedSubmitLunchOut, originalLunchInOut.start)
      );
      setLunchInEditText(getLunchInEditHelperText(updatedSubmitLunchIn, originalLunchInOut.end));
      onShiftTimeSave(
        updatedSubmitClockIn,
        updatedSubmitClockOut,
        updatedSubmitLunchOut,
        updatedSubmitLunchIn
      );
    }
    setShowSpinner(false);
  };

  const onCancel = () => {
    setSubmitClockIn(initialSubmissionClockInOut.start);
    setSubmitClockOut(initialSubmissionClockInOut.end);
    setSubmitLunchOut(initialSubmissionLunchInOut.start);
    setSubmitLunchIn(initialSubmissionLunchInOut.end);
    setShowSpinner(false);
    onShiftTimeEdit(false);
  };

  const onShiftTimeEditClick = async () => {
    onShiftTimeEdit(true);
    if (isRadarTimekeepingValidationsEnabled) {
      const { geofence } = await api.shift.fetchShiftDetails({
        shiftId: shift._id,
      });
      setShiftGeofence(geofence);
    }
  };

  return (
    <div className="shift-time-summary">
      <div>
        <IonAlert
          header={alert?.header}
          message={alert?.message}
          isOpen={!!alert}
          // onDidDismiss will override alert to null if we try to change the alert inside a modal button handler
          onWillDismiss={shiftDetailsAlerts.dismissAlert}
          buttons={alert?.buttons}
          mode="ios"
        />
        <ShiftActionTime
          shiftActionTime={submitClockIn}
          title="Clock in"
          isEditable={isEditable}
          isClearable={false}
          isTitleError={false}
          helperText={clockInEditText}
          facilityTimeZone={facilityTimezone}
          shift={shift}
          onChange={onClockInChange}
        />
        <ShiftActionTime
          shiftActionTime={submitLunchOut}
          title="Break start"
          isEditable={isEditable}
          isClearable={isSolveUnpaidBreaksEnabled || !hasGeofencedBreak}
          isTitleError={!isEditable && hasWorkedBreakTime}
          helperText={lunchOutEditText}
          facilityTimeZone={facilityTimezone}
          shift={shift}
          onChange={onLunchOutChange}
        />
        <ShiftActionTime
          shiftActionTime={submitLunchIn}
          title="Break end"
          isEditable={isEditable}
          isClearable={isSolveUnpaidBreaksEnabled || !hasGeofencedBreak}
          isTitleError={!isEditable && hasWorkedBreakTime}
          helperText={lunchInEditText}
          facilityTimeZone={facilityTimezone}
          shift={shift}
          onChange={onLunchInChange}
        />
        <ShiftActionTime
          shiftActionTime={submitClockOut}
          title="Clock out"
          isEditable={isEditable}
          isClearable={false}
          isTitleError={false}
          helperText={clockOutEditText}
          facilityTimeZone={facilityTimezone}
          shift={shift}
          onChange={onClockOutChange}
        />
        {!isEditable && (
          <>
            <div className="divider-line"></div>
            <IonText className="total-work-time">
              {hours} hr, {minutes} min
            </IonText>
          </>
        )}
        {!isEditable && hasWorkedBreakTime && (
          <Text
            variant="body2"
            sx={{
              color: (theme) => theme.palette.error.main,
            }}
            align="right"
            marginTop={2}
          >
            {`This timesheet contains ${
              breakDurationInMinutes === 0 ? "a skipped" : "an incomplete"
            } break.`}
          </Text>
        )}
      </div>
      <div>
        {!isEditable && isNewTimeSheetEnabled && isTimeEditButtonVisible && (
          <IonButton className="edit-button" fill="outline" onClick={onShiftTimeEditClick}>
            Edit
          </IonButton>
        )}
        {isEditable && (
          <>
            <IonButton
              className="cancel-button shift-time-summary-button"
              disabled={showSpinner}
              onClick={() => onCancel()}
            >
              Cancel
            </IonButton>
            <IonButton
              disabled={showSpinner}
              className="save-button shift-time-summary-button"
              onClick={() => {
                onSave();
              }}
            >
              {showSpinner && <IonSpinner className="spinner-style" />}Save
            </IonButton>
          </>
        )}
      </div>
    </div>
  );
};
